import {jwtDecode} from "jwt-decode";
import {Claims} from "../types/users/Claims";

export const validateEmail = (email : string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export const getJwtClaims = (token : string) => {
    return jwtDecode<Claims>(token);
}