import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import { User } from "firebase/auth";
import axios from "axios";
import {
    Stack,
    Button, 
    Checkbox, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Grid,
    TextField
} from "@mui/material";
import {
    addTopicTitle,
    cancel,
    enterValue,
    failedToAddTopic,
    selectAtLeastOneTopicYear,
    topicLabel,
    yearsLabel,
    editTopicTitle,
    failedToEditTopic,
    save
} from "../../../services/Messages";
import ApiService from "../../../services/ApiService";
import { Year } from "../../../types/departments/Year";
import { Topic } from "../../../types/topics/Topic";
import { LoadingComponent } from "../../../components/LoadingComponent";
import { MessageComponent, MessageProps } from "../../../components/MessageComponent";
import { convertYearsToCb } from "../utils/TopicsUtils";

interface AddOrEditTopicModalProps {
    show: boolean;
    topic: Topic | null;
    schoolId: string;
    departmentId: string;
    years: Array<Year>;
    user: User;
    onSubmitCallback: (topic : Topic, wasAdded : boolean) => void;
    onCancelCallback: () => void;
}

export const AddOrEditTopicModal = ({
    show,
    topic,
    schoolId,
    departmentId,
    years,
    user,
    onSubmitCallback,
    onCancelCallback
}: AddOrEditTopicModalProps) => {
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [message, setMessage] = useState<MessageProps>({message: "", variant: "info"});
    const [topicName, setTopicName] = useState<string>("");
    const [yearsCb, setYearsCb] = useState<Array<Year[]>>([]);
    const [selectedYears, setSelectedYears] = useState<Array<string>>([]);

    const isAdd = topic == null;

    useEffect( () => {
        setMessage({message: "", variant: "info"});
        setYearsCb(convertYearsToCb(years));

        if (topic) {
            setTopicName(topic.name);
            setSelectedYears([...topic.years]);
        } else {
            clearForm();
        }
    }, [topic, years]);

    const onYearsChanged = (event: ChangeEvent<HTMLInputElement>) => {
        const cb: string = "cb-"

        if (event.target.name.includes(cb)) {
            let addingYears = [...selectedYears];

            const year: string = event.target.name.substring(cb.length);
            const checked: boolean = event.target.checked;

            if (checked) {
                addingYears.push(year);
            } else {
                const index = addingYears.indexOf(year);
                if (index > -1) {
                    addingYears.splice(index, 1);
                }
            }
            setSelectedYears(addingYears);
        }
    }

    const getContent = () => {
        return <FormControl fullWidth>
            <TextField autoFocus label={topicLabel} variant="outlined"
            name="topic" id="topic" value={topicName} onChange={e => setTopicName(e.target.value)}/>
            <FormLabel component="legend">{yearsLabel}</FormLabel>
            <FormGroup>
                <Grid container>
                    {yearsCb.map((col, index) => {
                        return <Grid item xs={3} key={`item-${index}`}>
                            {col.map((year, index) => {
                                return <FormControlLabel
                                key={`checkbox-${index}`}
                                control={
                                <Checkbox onChange={onYearsChanged} 
                                    key={`cb-${year.id}`}
                                    id={`cb-${year.id}`}
                                    checked={selectedYears.includes(year.name)}
                                    name={`cb-${year.name}`} />
                                }
                                label={year.name}
                            />
                            })}
                        </Grid>
                    })}
                </Grid>        
            </FormGroup>
    </FormControl>
    }

    const onKeyUp = (e : KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            onSubmit();
        }
    }

    const onCancel = () => {
        setMessage({message: "", variant: "info"});
        onCancelCallback();
    }

    const clearForm = () => {
        setTopicName("");
        setSelectedYears([]);
    }

    const validateTopic = () => {
        if (topicName.trim().length === 0) {
            setMessage({message: enterValue, variant: "error"});
            return false;
        }

        if (selectedYears.length === 0) {
            setMessage({message: selectAtLeastOneTopicYear, variant: "error"});
            return false;
        }
        return true;
    }

    const addTopic = async () => {
        setMessage({message: "", variant: "info"});

        if (!validateTopic()) {
            return;
        }

        const token = await user.getIdToken();
        const response = await ApiService.addTopic({
            schoolId: schoolId,
            departmentId: departmentId,
            years: selectedYears,
            topic: topicName,
        }, token);

        const topic: Topic = {
            id: response.data["id"],
            name: response.data["name"],
            years: response.data["years"],
            numQuestions: 0
        };

        clearForm();
        onSubmitCallback(topic, isAdd);
    }

    const editTopic = async () => {
        setMessage({message: "", variant: "info"});

        if (!validateTopic()) {
            return;
        }

        let token = await user.getIdToken();
        const response = await ApiService.updateTopic(topic!.id, {
            schoolId: schoolId,
            topic: topicName,
            years: selectedYears
        }, token);

        const editedTopic: Topic = {
            id: response.data["id"],
            name: response.data["name"],
            years: response.data["years"],
            numQuestions: topic!.numQuestions
        };

        clearForm();
        onSubmitCallback(editedTopic, isAdd);
    }

    const onSubmit = async () => {
        try {
            setIsBusy(true);
            isAdd ? await addTopic() : await editTopic();
            setIsBusy(false);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.data?.error) {
                setMessage({message: error.response.data.error, variant: "error"});
            } else {
                setMessage({message: isAdd ? failedToAddTopic : failedToEditTopic, variant: "error"});
            }
            setIsBusy(false);
        }
    }

    return <Dialog open={show} onKeyUp={onKeyUp} onClose={onCancel} disableRestoreFocus>
        <DialogTitle>{isAdd ? addTopicTitle : editTopicTitle}</DialogTitle>
        <DialogContent>
            <Stack spacing={1} sx={{m: 1}}>
                {message.message.length > 0 && <MessageComponent message={message.message} variant={message.variant}/>}
                {isBusy ? <LoadingComponent/> : getContent()}
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" disabled={isBusy} onClick={onCancel}>
                {cancel}
            </Button>
            <Button disabled={isBusy} type="submit" onClick={onSubmit}>
                {save}
            </Button>
        </DialogActions>
    </Dialog>
}