import {Dispatch, SetStateAction} from "react";
import {GridToolbarContainer, GridToolbarFilterButton} from "@mui/x-data-grid";

interface DataTableToolbarProps {
    setFilterButtonEl: Dispatch<SetStateAction<HTMLButtonElement | null>>;
}

export const DataTableToolbar = ({setFilterButtonEl}: DataTableToolbarProps) => {
    return <GridToolbarContainer>
        <GridToolbarFilterButton ref={setFilterButtonEl} />
    </GridToolbarContainer>
}