import {useContext} from "react";
import {Box, Stack, Typography} from "@mui/material";
import {AuthContext} from "../../context/AuthContext";
import {ChangePasswordForm} from "./components/ChangePasswordForm";
import {doChangePassword, userSettingsFor} from "../../services/Messages";

export const UserProfilePage = () => {
    const authContext = useContext(AuthContext);
    return <Box sx={{m: 2}}>
        <Stack spacing={2}>
            <Typography variant="h4" gutterBottom>
                {userSettingsFor}&nbsp;
                {authContext.user ?
                            authContext.user.displayName ? authContext.user.displayName : authContext.user.email : ""}
            </Typography>
            <Typography variant="h5">
                {doChangePassword}
            </Typography>
            <ChangePasswordForm firebaseUser={authContext.user} />
        </Stack>
    </Box>
}
