import {Year} from "../../../types/departments/Year";

export const convertYearsToCb = (years : Array<Year>) => {
    let result : Year[][] = [];
    let step = 3;

    for(let i = 0; i < years.length; i += step) {
        result.push(years.slice(i, i+step));
    }
    return result;
}