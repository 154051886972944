import { Navigate } from "react-router-dom";
import {useContext} from "react";
import {AuthContext} from "./AuthContext";
import {LoadingPageComponent} from "../components/LoadingComponent";

export const RequireAuth = ({children}: { children: JSX.Element }) => {
    const authContext = useContext(AuthContext);

    return (
        <>
            {authContext.loading ? <LoadingPageComponent/>:
                authContext.user ? children : <Navigate to="/login" replace/>}
        </>
    )
};