import {useContext, useEffect, useState} from "react";
import {Box, Button, Stack, Typography} from "@mui/material";
import {GridColDef, GridPaginationModel} from "@mui/x-data-grid";
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {DatabaseUser} from "../../types/users/DatabaseUser";
import ApiService from "../../services/ApiService";
import {AuthContext} from "../../context/AuthContext";
import {
    failedToGetUsers,
    userDeleted,
    userInvited,
    usersTitle,
    doDelete,
    invite,
    dashboardTitle,
    emailLabel,
    roleLabel,
} from "../../services/Messages";
import {Constants} from "../../Constants";
import {Department} from "../../types/departments/Department";
import {LoadingComponent} from "../../components/LoadingComponent";
import {Breadcrumbs} from "../../components/Breadcrumbs";
import {DataTable} from "../../components/DataTable";
import {DeleteUserModal} from "./components/DeleteUserModal";
import {InviteUserModal} from "./components/InviteUserModal";
import {MessageComponent, MessageProps} from "../../components/MessageComponent";

export const UsersPage = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [message, setMessage] = useState<MessageProps>({message: "", variant: "info"});
    const [paginationModel, setPaginationModel] = useState<GridPaginationModel>({
        pageSize: Constants.ITEMSPERPAGE, 
        page: 0
    });
    const [totalUsers, setTotalUsers] = useState<number>(0);
    const [users, setUsers] = useState<DatabaseUser[]>([]);
    const [user, setUser] = useState<DatabaseUser | null>(null);
    const [schoolId, setSchoolId] = useState<string>("");
    const [departments, setDepartments] = useState<Department[]>([]);
    const [showInviteUser, setShowInviteUser] = useState<boolean>(false);
    const [showDeleteUser, setShowDeleteUser] = useState<boolean>(false);

    const authContext = useContext(AuthContext);

    const columns : GridColDef[] = [{
            field: "email", 
            headerName: emailLabel, 
            width: 400, 
            sortable: false, 
            filterable: false,
            hideable: false
        }, {
            field: "role", 
            headerName: roleLabel, 
            width: 200, 
            sortable: false, 
            filterable: false,
            hideable: false
        }, { 
            field: "delete", 
            headerName: "", 
            width: 120,
            sortable: false,
            filterable: false,
            hideable: false,
            renderCell: params => {
                const usr : DatabaseUser = params.row as DatabaseUser
                return <Button 
                        variant="contained"
                        disabled={usr.email === authContext.user?.email}
                        onClick={(event) => {
                            onDeleteUser(usr);
                        }}>
                        <DeleteIcon/>&nbsp;{doDelete}
                </Button>
            }
         }
    ]

    useEffect(() => {
        const fetchData = async (schoolId : string, token : string) => {
            setSchoolId(schoolId);
            const users = await ApiService.getUsers(schoolId, token, 1);
            setUsers(users.users);
            setTotalUsers(users.total);
            const departments = await ApiService.getDepartments(schoolId, token);
            setDepartments(departments);
        }
        if (!authContext.loading && authContext.userCredential) {
            fetchData(authContext.userCredential.schoolId, authContext.userCredential.token).then(() => {
                setMessage({ message: "", variant: "info" });
                setLoading(false);
                setDataLoading(false);
            }).catch(e => {
                setMessage({ message: failedToGetUsers, variant: "error" });
                setLoading(false);
                setDataLoading(false);
            });
        }
    }, [authContext.loading, authContext.user, authContext.userCredential]);

    const getUsers = async (page : number) => {
        const token = authContext.userCredential?.token;
        const users = await ApiService.getUsers(schoolId, token, page);
        setUsers(users.users);
        setTotalUsers(users.total);
    }

    useEffect(() => {
        if (schoolId.length > 0) {
            setDataLoading(true);
            getUsers(paginationModel.page + 1).then(() =>{
                setMessage({ message: "", variant: "info" });
                setDataLoading(false);
            }).catch(e => {
                setMessage({ message: failedToGetUsers, variant: "error" });
                setDataLoading(false);
            });
        }
    }, [paginationModel.page, schoolId.length]);

    const showFirstPage = async () => {
        setPaginationModel({
            pageSize: Constants.ITEMSPERPAGE, 
            page: 0
        });
        await getUsers(paginationModel.page + 1);
    }

    const onDeleteUser = (user : DatabaseUser) => {
        setMessage({message: "", variant: "info"});
        setUser(user);
        setShowDeleteUser(true);
    }

    const onCancelDeleteUser = () => {
        setUser(null);
        setShowDeleteUser(false);
    }

    const onUserDeleted = async () => {
        await showFirstPage();
        setUser(null);
        setShowDeleteUser(false);
        setMessage({ message: userDeleted, variant: "success" });
    }

    const onInviteUser = () => {
        setMessage({message: "", variant: "info"});
        setShowInviteUser(true);
    }

    const onCancelInviteUser = () => {
        setShowInviteUser(false);
    }

    const onUserInvited = async () => {
        await showFirstPage();
        setTotalUsers(totalUsers + 1);
        setShowInviteUser(false);
        setMessage({ message: userInvited, variant: "success" });
    }

    const getContent = () => {
        return <Box sx={{m: 2}}>
            <InviteUserModal
                show={showInviteUser}
                schoolId={schoolId}
                departments={departments}
                user={authContext.user!}
                onSubmitCallback={onUserInvited}
                onCancelCallback={onCancelInviteUser} />

            <DeleteUserModal
                show={showDeleteUser}
                user={authContext.user!}
                dbUser={user}
                onCancelCallback={onCancelDeleteUser}
                onSubmitCallback={onUserDeleted}/>

            <Stack spacing={3}>
                <Breadcrumbs crumbs={[
                    {name: dashboardTitle, path: "/dashboard"},
                    {name: usersTitle, path: "#"}
                ]}/>
                <Typography variant="h4" gutterBottom>{usersTitle}</Typography>
                {message.message.length > 0 && <MessageComponent message={message.message} variant={message.variant}/>}

                <DataTable 
                    loading={dataLoading}
                    disableColumnMenu={true}
                    columns={columns}
                    rows={users}
                    totalRows={totalUsers}
                    paginationModel={paginationModel}
                    paginationModelChanged={setPaginationModel}/>
            </Stack>
            <Box sx={{mt: 2}}>
                <Button variant="contained" 
                    onClick={onInviteUser}>
                        <AddIcon/>&nbsp;{invite}
                </Button>
            </Box>
        </Box>
    }

    return (
        <>
            {loading ? <LoadingComponent/> : getContent()}
        </>
    );
}