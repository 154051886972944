import {useContext, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {Box, Stack, Typography} from "@mui/material";
import {AuthContext} from "../../context/AuthContext";
import ApiService from "../../services/ApiService";
import {
    failedToGetTopics,
    dashboardTitle,
    departmentsTitle,
    topicsLabel
} from "../../services/Messages";
import {Year} from "../../types/departments/Year";
import TopicsTable from "./components/TopicsTable";
import {Breadcrumbs} from "../../components/Breadcrumbs";
import {LoadingComponent} from "../../components/LoadingComponent";
import {MessageComponent, MessageProps} from "../../components/MessageComponent";

export const TopicsPage = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [message, setMessage] = useState<MessageProps>({message: "", variant: "info"});
    const [departmentName, setDepartmentName] = useState<string>("");
    const [schoolId, setSchoolId] = useState<string>("");
    const [years, setYears] = useState<Array<Year>>([]);

    let params = useParams();
    const authContext = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async (token : string) => {
            const response = await ApiService.getDepartment(params.deptId!, token)
            setDepartmentName(response.name);
            setSchoolId(response.schoolId.toString());

            let props: Array<Year> = [];
            response.years?.forEach((year : Year) => {
                props.push(year);
            });
            setYears(props);
        };
        if (!authContext.loading && authContext.userCredential) {
            fetchData(authContext.userCredential.token).then(() => {
                setMessage({ message: "", variant: "info" });
                setLoading(false);
            }).catch(e => {
                setMessage({message: failedToGetTopics, variant: "error"});
                setLoading(false);
            });
        }
    }, [params.deptId, authContext.loading, authContext.user, authContext.userCredential])

    const getContent = () => {
        return <Box sx={{m: 2}}>
            <Stack spacing={3}>
                <Breadcrumbs crumbs={[
                    {name: dashboardTitle, path: "/dashboard"},
                    {name: departmentsTitle, path: "/depts"},
                    {name: `${departmentName}`, path: "#"}
                ]} />

                <Typography variant="h4" gutterBottom>{departmentName} {topicsLabel}</Typography>
                {message.message.length > 0 && <MessageComponent message={message.message} variant={message.variant}/>}

                <TopicsTable
                    schoolId={schoolId}
                    departmentId={params.deptId!}
                    years={years}
                    user={authContext.user!}/>
            </Stack>
        </Box>
    }

    return (
        <>
            {loading ? <LoadingComponent/> : getContent()}
        </>
    );
}