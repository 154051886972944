import {
    Box, 
    Card, 
    CardActionArea, 
    CardContent, 
    Stack, 
    Typography
} from "@mui/material";
import SportsEsportsIcon from '@mui/icons-material/SportsEsports';
import {createGame, createGameDescription} from "../../../services/Messages";

export const CreateGameCard = () => {
    return <Box sx={{boxShadow: 2, height: "100%"}}>
        <Card variant="outlined" sx={{height: "100%"}}>
            <CardContent>
                <CardActionArea href="https://teach.studyparty.monocool.net" 
                    rel="noreferrer" target="_blank" disableRipple disableTouchRipple>
                    <Stack spacing={1} alignContent="center" textAlign="center">
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <SportsEsportsIcon style={{fontSize: 40, color: "#22B8DC"}}/>
                        </Box>
                        <Typography variant="h5">
                            {createGame}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {createGameDescription}
                        </Typography>
                    </Stack>
                </CardActionArea>
            </CardContent>
        </Card>
    </Box>
}