import { User } from "firebase/auth";
import React from "react";
import { ChangeEvent, KeyboardEvent, useEffect, useState } from "react";
import axios from "axios";
import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    FormControl,
    FormLabel,
    Grid,
    Stack
} from "@mui/material";
import {
    addAnotherDefinition,
    addTermTitle,
    cancel,
    definitions,
    editTermTitle,
    enterRequiredValues,
    enterWrongDefinitions,
    failedToAddTerm,
    failedToEditTerm,
    optional,
    removeDefinition,
    resourcesLabel,
    save,
    term,
    wrongDefinitions
} from "../../../services/Messages";
import ApiService from "../../../services/ApiService";
import { Question } from "../../../types/questions/Question";
import { MessageComponent, MessageProps } from "../../../components/MessageComponent";
import { LoadingComponent } from "../../../components/LoadingComponent";

interface AddOrEditQuestionModalProps {
    show: boolean;
    topicId: string;
    question: Question | null;
    user: User;
    onSubmitCallback: (question : Question, wasAdded : boolean) => void;
    onCancelCallback: () => void;
}

export const AddOrEditQuestionModal = ({
    show,
    topicId,
    question,
    user,
    onSubmitCallback,
    onCancelCallback
}: AddOrEditQuestionModalProps) => {
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [message, setMessage] = useState<MessageProps>({message: "", variant: "info"});
    const [questionText, setQuestionText] = useState<string>("");
    const [answers, setAnswers] = useState<Array<string>>([]);
    const [wrongAnswers, setWrongAnswers] = useState<Array<string>>([]);
    const [resources, setResources] = useState<string>("");

    const isAdd = question == null;

    useEffect(() => {
        setMessage({message: "", variant: "info"});

        if(question !== null) {
            setQuestionText(question.text);
            const qa = question.answers.map(a => {
                return a.text;
            });
            setAnswers(qa);
            if (question.wrongAnswers == null) {
                // Always show one wrong answer
                setWrongAnswers([""]);
            } else {
                const wqa = question.wrongAnswers.map(a => {
                    return a
                });
                setWrongAnswers(wqa);
            }
            setResources(question.resources ?? "");
        } else {
            clearForm();
        }
    }, [question]);

    const onAnswersChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let def: string = "def-"
        if (event.target.name.includes(def)) {
            let index: number = Number(event.target.name.substring(def.length));
            let ans = [...answers];
            ans.splice(index, 1, event.target.value);
            setAnswers(ans);
        }
    }

    const onWrongAnswersChanged = (event: ChangeEvent<HTMLInputElement>) => {
        let def: string = "w-def-"
        if (event.target.name.includes(def)) {
            let index: number = Number(event.target.name.substring(def.length));
            let wa = [...wrongAnswers];
            wa.splice(index, 1, event.target.value);
            setWrongAnswers(wa);
        }
    }

    const onAddAnswer = () => {
        setMessage({message: "", variant: "info"});
        let ans = [...answers]; 
        ans.push("");
        setAnswers(ans);
    }

    const onDeleteAnswer = (index : number) => {
        setMessage({message: "", variant: "info"});
        let ans = [...answers];
        ans.splice(index, 1);
        setAnswers(ans);
    }

    const onAddWrongAnswer = () => {
        setMessage({message: "", variant: "info"});
        let wa = [...wrongAnswers];
        wa.push("");
        setWrongAnswers(wa);
    }

    const onDeleteWrongAnswer = (index : number) => {
        setMessage({message: "", variant: "info"});
        let wa = [...wrongAnswers];
        wa.splice(index, 1);
        setWrongAnswers(wa);
    }

    const getContent = () => {
        return <FormControl fullWidth>
            <FormLabel component="legend">{term}</FormLabel>
            <TextField autoFocus variant="outlined" required
                value={questionText} name="term" id="term" onChange={e => setQuestionText(e.target.value)}/>

            <FormLabel component="legend">{definitions}</FormLabel>
            <Grid container spacing={0.5}>
                {answers.map((a, index) => {
                    return <React.Fragment key={`frag-${index}`}>
                        <Grid item xs={9} sm={9} md={9} key={`row-${index}`}>
                            <TextField required fullWidth
                                variant="outlined"
                                key={`def-${index}`}
                                name={`def-${index}`}
                                id={`def-${index}`}
                                value={a}
                                onChange={onAnswersChanged}/>
                        </Grid>
                        {index !== 0 && <Grid item xs={3} sm={3} md={3} key={`col-del-${index}`}>
                            <Button variant="contained" key={`button-del-${index}`} onClick={() => onDeleteAnswer(index)}>
                                {removeDefinition}
                            </Button>
                        </Grid>}
                        {index === 0 && <Grid item xs={3} sm={3} md={3} key={`col-add-another-${index}`}>
                            <Button variant="contained" key={`button-add-another-${index}`} onClick={onAddAnswer} type="submit">
                                {addAnotherDefinition}
                            </Button>
                        </Grid>}
                    </React.Fragment>
                })}
            </Grid>

            <FormLabel component="legend">{`${wrongDefinitions} ${optional}`}</FormLabel>
            <Grid container spacing={0.5}>
                {wrongAnswers.map((a, index) => {
                    return <React.Fragment key={`w-frag-${index}`}>
                        <Grid item xs={9} sm={9} md={9} key={`w-row-${index}`}>
                            <TextField required fullWidth
                                variant="outlined"
                                key={`w-def-${index}`}
                                name={`w-def-${index}`}
                                id={`w-def-${index}`}
                                value={a}
                                onChange={onWrongAnswersChanged}/>
                        </Grid>
                        {index !== 0 && <Grid item xs={3} sm={3} md={3} key={`w-col-del-${index}`}>
                            <Button variant="contained" key={`w-button-del-${index}`} onClick={() => onDeleteWrongAnswer(index)}>
                                {removeDefinition}
                            </Button>
                        </Grid>}
                        {index === 0 && <Grid item xs={3} sm={3} md={3} key={`w-col-add-another-${index}`}>
                            <Button variant="contained" key={`w-button-add-another-${index}`} disabled={wrongAnswers.length >= 3}
                                onClick={onAddWrongAnswer} type="submit">
                                {addAnotherDefinition}
                            </Button>
                        </Grid>}
                    </React.Fragment>
                })}
            </Grid>

            <FormLabel component="legend">{`${resourcesLabel} ${optional}`}</FormLabel>
            <TextField variant="outlined"
                value={resources} name="resources" id="resources" onChange={e => setResources(e.target.value)}/>
        </FormControl>
    }

    const onKeyUp = (e : KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            onSubmit();
        }
    }

    const onCancel = () => {
        setMessage({message: "", variant: "info"});
        onCancelCallback();
    }

    const validateQuestion = () => {
        if (questionText.trim().length === 0) {
            setMessage({message: enterRequiredValues, variant: "error"});
            return false;
        }

        const hasEmptyAnswers = answers.some(a => a.trim() === "");
        if (hasEmptyAnswers) {
            setMessage({message: enterRequiredValues, variant: "error"});
            return false;
        }

        const hasEmptyWrongAnswers = wrongAnswers.some(a => a.trim() === "");
        if (hasEmptyWrongAnswers && wrongAnswers.length > 1) {
            setMessage({message: enterWrongDefinitions, variant: "error"});
            return false;
        }
        return true;
    }

    const clearForm = () => {
        setQuestionText("");
        // Always show one answer
        setAnswers([""]);
        // Always show one wrong answer
        setWrongAnswers([""]);
        setResources("");
    }

    const addQuestion = async () => {
        setMessage({message: "", variant: "info"});

        if (!validateQuestion()) {
            return;
        }

        const enteredWrongAnswers = 
            wrongAnswers.length === 1 && wrongAnswers[0].trim().length === 0 ? undefined : wrongAnswers;

        let token = await user.getIdToken();
        const response = await ApiService.addQuestion({
            topicId: topicId,
            question: questionText,
            answers: answers,
            wrongAnswers: enteredWrongAnswers,
            resources: resources.length === 0 ? undefined : resources,
        }, token);

        const addedAnswers = answers.map(a => {
            return {
                text: a
            }
        });

        const addedQuestion: Question = {
            id: response.data["id"],
            text: response.data["text"],
            answers: addedAnswers,
            wrongAnswers: enteredWrongAnswers,
            resources: resources.length === 0 ? undefined : resources,
        };
        clearForm();
        onSubmitCallback(addedQuestion, isAdd);
    }
    
    const editQuestion = async () => {
        setMessage({message: "", variant: "info"});

        if (!validateQuestion()) {
            return;
        }

        const enteredWrongAnswers = 
            wrongAnswers.length === 1 && wrongAnswers[0].trim().length === 0 ? undefined : wrongAnswers;

        let token = await user.getIdToken();
        let response = await ApiService.updateQuestion(question!.id, {
            question: questionText,
            answers: answers,
            wrongAnswers: enteredWrongAnswers,
            resources: resources.length === 0 ? undefined : resources,
        }, token);

        const editedAnswers = answers.map(a => {
            return {
                text: a
            }
        });

        let editedQuestion: Question = {
            id: response.data["id"],
            text: response.data["text"],
            answers: editedAnswers,
            wrongAnswers: enteredWrongAnswers,
            resources: resources.length === 0 ? undefined : resources,
        };
        clearForm();
        onSubmitCallback(editedQuestion, isAdd);
    }

    const onSubmit = async () => {
        try {
            setIsBusy(true);
            isAdd ? await addQuestion() : await editQuestion();
            setIsBusy(false);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.data?.error) {
                setMessage({message: error.response.data.error, variant: "error"});
            } else {
                setMessage({message: isAdd ? failedToAddTerm : failedToEditTerm, variant: "error"});
            }
            setIsBusy(false);
        }
    }

    return <Dialog open={show} onKeyUp={onKeyUp} onClose={onCancel} disableRestoreFocus fullWidth
        maxWidth="md">
        <DialogTitle>{isAdd ? addTermTitle : editTermTitle}</DialogTitle>
        <DialogContent>
            <Stack spacing={1} sx={{m: 1}}>
                {message.message.length > 0 && <MessageComponent message={message.message} variant={message.variant}/>}
                {isBusy ? <LoadingComponent/> : getContent()}
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" disabled={isBusy} onClick={onCancel}>
                {cancel}
            </Button>
            <Button disabled={isBusy} onClick={onSubmit}>
                {save}
            </Button>
        </DialogActions>
    </Dialog>
}