import { initializeApp } from 'firebase/app';
import {
    User,
    EmailAuthProvider,
    browserLocalPersistence,
    browserSessionPersistence,
    getAuth,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    updatePassword,
    reauthenticateWithCredential
} from "firebase/auth";
import {failedChangePassword} from "./Messages";

const firebaseConfig = {
    apiKey: "AIzaSyBLtifJIiW84wAaIsS5n-65JCqewxxSg-I",
    authDomain: "superstudyparty.firebaseapp.com",
    projectId: "superstudyparty",
    storageBucket: "superstudyparty.appspot.com",
    messagingSenderId: "445989614402",
    appId: "1:445989614402:web:afc3b6f57e770febf68edb"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

const logIn = async (email : string, password : string, rememberMe : boolean) => {
    await auth.setPersistence(rememberMe ? browserLocalPersistence : browserSessionPersistence);
    return await signInWithEmailAndPassword(auth, email, password);
}

const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            unsubscribe();
            resolve(user);
        }, reject);
    });
}

const forgotPassword = async (email : string) => {
    return await sendPasswordResetEmail(auth, email);
}

const changePassword = async (user : User | null, currentPassword : string, newPassword : string) => {
    if(user) {
        const credential = EmailAuthProvider.credential(user.email!, currentPassword);
        await reauthenticateWithCredential(user, credential);
        return await updatePassword(user, newPassword);
    }
    else {
        throw new Error(failedChangePassword);
    }
}

const logOut = async () => {
    return await signOut(auth);
}

const AuthService = {
    auth,
    logIn,
    getCurrentUser,
    forgotPassword,
    changePassword,
    logOut
}

export default AuthService;