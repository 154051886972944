export const notAuthorised : string = "This user is not authorised";
export const notAuthorisedOrUserConfigError : string = "This user is not authorised. If you believe that you should have access, please contact your System Administrator"
export const schoolIsLockedMessage : string = "The access to your school is currently locked. Please contact your System Administrator";
export const failedLogin : string = "Login failed. Please try again";
export const failedForgotPassword : string = "Failed to request a password reset. Please try again";
export const successForgotPassword : string = "We have emailed you a link to reset your password. Please also check your spam email folder";

// Change Password
export const failedChangePasswordMatch : string = "Passwords do not match. Please try again";
export const failedChangePasswordLength : string = "Password length should be at least 6 characters";
export const failedChangePassword : string = "Failed to change password. Please ensure that you have entered your current password correctly and try again";
export const successChangePassword : string = "Your password was changed. You can click here to return to the dashboard";

export const failedToGetUsers : string = "Failed to get users.";
export const userDeleted : string = "The user was deleted";
export const userInvited : string = "The user was invited";

export const failedLogOut : string = "Failed to log out.";
export const failedToGetDepartments : string = "Failed to get departments";
export const failedToGetTopics : string = "Failed to get topics";
export const failedToGetTerms : string = "Failed to get terms and definitions";
export const failedToAddTerm : string = "Failed to add term due to an error";
export const failedToEditTerm : string = "Failed to edit term due to an error";
export const failedToDeleteTerm : string = "Failed to delete term due to an error";
export const selectAtLeastOneTopicYear : string = "Please select at least one year";
export const failedToAddTopic : string = "Failed to add topic due to an error";
export const failedToEditTopic : string = "Failed to edit topic due to an error";

export const topicDeleted : string = "Topic deleted";
export const topicAdded : string = "Topic added";
export const topicEdited : string = "Topic edited";

export const editTopicTitle : string = "Edit Topic";
export const topicLabel : string = "Topic";
export const topicsLabel : string = "Topics";
export const yearsLabel : string = "Years";
export const numberOfTermsLabel : string = "No. of Terms";

export const addTopicTitle : string = "Add Topic";
export const add : string = "Add";

export const deleteTopicWithTerms : string = "Please confirm by typing the topic name and then press delete"
export const deleteTopicNoTerms : string = "Please confirm delete or press cancel";
export const failedToDeleteTopic : string = "Failed to delete topic due to an error";
export const topicNameDoesNotMatch : string = "Please type the topic name exactly to delete this topic";

export const addTopicGetStarted : string = "Add a topic to get started!";
export const addTermGetStarted : string = "Add a term to get started!";

export const editTermTitle : string = "Edit Term";
export const addTermTitle : string = "Add Term";
export const term : string = "Term";
export const terms : string = "Terms";
export const definitions : string = "Definition(s)";
export const wrongDefinitions : string = "Wrong Definitions";
export const enterWrongDefinitions : string = "Please enter or remove wrong definitions";
export const addAnotherDefinition : string = "Add";
export const removeDefinition : string = "Remove";
export const resourcesLabel : string = "Resources";

export const termDeleted : string = "Term deleted";
export const termAdded : string = "Term added";
export const termEdited : string = "Term edited";

export const enterValue : string = "Please enter all values";
export const optional : string = "(Optional)";
export const enterRequiredValues : string = "Please enter all required values";

export const requireMinimumTerms : string = "Please add a minimum of 4 terms for a playable topic.";

export const logOut : string = "Log Out";
export const requestPasswordReset : string = "Request Password Reset";

export const enterCurrentPassword : string = "Enter your current password";
export const enterNewPassword : string = "Enter your new password";
export const confirmNewPassword : string = "Confirm your new password";
export const doChangePassword : string = "Change Password";

export const userSettingsTitle : string = "User Settings";
export const userSettingsFor : string = "User Settings for";

export const forgotPasswordQuestion : string = "Forgot password?";
export const backToLogin : string = "Back to Log In";
export const forgotPassword : string = "Enter the email address associated with your account and we will send you a link to reset your password";
export const enterEmail : string = "Enter your email address";
export const enterPassword : string = "Enter your password";

export const rememberMe : string = "Remember me";

export const altTextLogo : string = "Super Study Party Logo";

/// Dashboard
export const dashboardTitle : string = "Dashboard";
export const welcome : string = "Welcome, to your administrator portal";
export const failedToLoadDashboard : string = "Failed to load dashboard data. Please contact your System Administrator";
export const logIn : string = "Log In";
export const logInDescription : string = "Log in to the portal";
export const manage : string = "Manage";
export const manageDescription : string = "Manage topics, terms and definitions";
export const createGame : string = "Create Game";
export const createGameDescription : string = "Create a game ready for your students to take part"
export const manageUsers : string = "Manage Users";
export const manageUsersDescription : string = "Invite or delete users";
export const manageTopicsFor : string = "Manage topics for";
export const allDepartments : string = "View All Departments";

/// Dashboard Change Settings
export const failedToSetDefaultDepartment : string = "Failed to set default department";
export const doSetDefaultDepartment : string = "Set Your Department";
export const noDefaultDepartment : string = "None";
export const doSet : string = "Set";
export const recommendChangePassword : string = "We very strongly recommend that you change your password! Please click on this message to change your password";

// CRUD department
export const addDepartmentTitle = "Add Department";
export const editDepartmentTitle = "Edit Department";
export const departmentNameLabel = "Department Name";
export const failedToAddDepartment = "Failed to add department";
export const failedToEditDepartment = "Failed to edit department";
export const failedToDeleteDepartment = "Failed to delete department";
export const departmentHasTopics = "Cannot delete a department which has topic(s)";
export const reviewDepartmentTopics = "Please click here to view and delete topic(s)"

// Users page
export const usersTitle : string = "Users";
export const emailLabel : string = "Email";
export const roleLabel : string = "Role";
export const invalidEmailFormat : string = "Invalid email address format";
export const displayNameLabel : string = "Display Name";
export const departmentLabel : string = "Department";
export const noDepartment : string = "No Default Department";
export const inviteTeacher : string = "Invite Teacher";
export const invite : string = "Invite";
export const userLabel : string = "user";
export const confirmDeleteUser : string = "Please confirm by typing the user's email and pressing delete";
export const userEmailDoesNotMatch : string = "Please type the user's email exactly to delete this user";
export const failedToDeleteUser : string = "Failed to delete user";
export const failedToInviteTeacher : string = "Failed to invite teacher";

export const departmentsTitle : string = "Departments";
export const departmentsDefaultTitle : string = "Default";

export const confirmDelete : string = "Confirm Delete?";
export const confirmDeleteDescription : string = "Please confirm delete or press cancel.";
export const doDelete : string = "Delete";
export const cancel : string = "Cancel";
export const save : string = "Save";

export const doEdit : string = "Edit";

/// Footer
export const monocool : string = "Monocool";