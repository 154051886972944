import {User} from "firebase/auth";
import {KeyboardEvent, useEffect, useState} from "react";
import {
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    Link,
    Stack,
    Typography
} from "@mui/material";
import {
    cancel,
    confirmDeleteDescription,
    departmentHasTopics,
    reviewDepartmentTopics,
    departmentLabel,
    doDelete,
    failedToDeleteDepartment,
    failedToGetTopics
} from "../../../services/Messages";
import ApiService from "../../../services/ApiService";
import {Department} from "../../../types/departments/Department";
import {MessageComponent, MessageProps} from "../../../components/MessageComponent";
import {LoadingComponent} from "../../../components/LoadingComponent";

interface DeleteDepartmentModalProps {
    show: boolean;
    department: Department | null;
    user: User,
    onCancelCallback: () => void;
    onSubmitCallback: (department : Department) => void
}

export const DeleteDepartmentModal = ({
                                    show,
                                    department,
                                    user,
                                    onCancelCallback,
                                    onSubmitCallback
                               } : DeleteDepartmentModalProps) => {
    const [message, setMessage] = useState<MessageProps>({message: "", variant: "info"});
    const [isBusy, setIsBusy] = useState<boolean>(true);
    const [totalTopics, setTotalTopics] = useState<number>(0);

    useEffect(() => {
        const fetchData = async () => {
            setMessage({message: "", variant: "info"});
            if (department) {
                const token = await user.getIdToken();
                const topics = await ApiService.getTopics(department.id.toString(), token);
                setTotalTopics(topics.length);
            } else {
                setMessage({message: failedToDeleteDepartment, variant: "error"});
            }
        }

        fetchData().then(() => {
            setIsBusy(false);
        }).catch(e => {
            setMessage({message: failedToGetTopics, variant: "error"});
            setIsBusy(false);
        });
    }, [department, user]);

    const getContent = () => {
        return <>
            {totalTopics > 0 && <>
                <Typography variant="h6" gutterBottom>{departmentHasTopics}</Typography>
                <Typography variant="body1">
                    <Link href={`depts/${department?.id}/topics`} underline="hover">
                        {reviewDepartmentTopics}
                    </Link>
                </Typography>
            </>}
            {totalTopics === 0 && confirmDeleteDescription}
        </>
    }

    const onDelete = async () => {
        try {
            setMessage({message: "", variant: "info"});
            setIsBusy(true);
            const token = await user.getIdToken();
            await ApiService.deleteDepartment(department!.id, token);
            setIsBusy(false);
            onSubmitCallback(department!);
        } catch(e) {
            setMessage({message: failedToDeleteDepartment, variant: "error"});
            setIsBusy(false);
        }
    }

    const onKeyUp = (e : KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            onDelete();
        }
    }

    return <Dialog open={show} onKeyUp={onKeyUp} onClose={onCancelCallback} disableRestoreFocus>
        <DialogTitle>{`${doDelete} ${departmentLabel}: ${department?.name}?`}</DialogTitle>
        <DialogContent>
            <Stack spacing={1} sx={{m: 1}}>
                {message.message.length > 0 && <MessageComponent message={message.message} variant={message.variant}/>}
                {isBusy ? <LoadingComponent/> : getContent()}
            </Stack>     
        </DialogContent>
        <DialogActions>
            <Button color="secondary" disabled={isBusy} onClick={onCancelCallback}>
                {cancel}
            </Button>
            <Button disabled={!department || isBusy || totalTopics > 0} onClick={onDelete}>
                {doDelete}
            </Button>
        </DialogActions>
    </Dialog>
}