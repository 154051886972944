import {useContext, useEffect, useState} from "react";
import {Box, Grid, Typography} from "@mui/material";
import ApiService from "../../services/ApiService";
import {AuthContext} from "../../context/AuthContext";
import {
    recommendChangePassword,
    welcome,
    failedToLoadDashboard,
    schoolIsLockedMessage
} from "../../services/Messages";
import {Constants} from "../../Constants";
import {UserData} from "../../types/users/UserData";
import {SelfUser} from "../../types/users/SelfUser";
import {LoadingComponent} from "../../components/LoadingComponent";
import {MessageComponent, MessageProps} from "../../components/MessageComponent";
import {DashboardSa} from "./components/DashboardSa";
import {DashboardNonSa} from "./components/DashboardNonSa";

export const DashboardPage = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [message, setMessage] = useState<MessageProps>({message: "", variant: "info"});
    const [schoolIsLocked, setSchoolIsLocked] = useState<boolean>(false);
    const [userData, setUserData] = useState<UserData>({data: []});
    const [mustChangePassword, setMustChangePassword] = useState<boolean>(false);

    const {loading : authLoading, userCredential} = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async (token : string) => {
            const response = await ApiService.getSelfUser(token);
            const self : SelfUser = response;
            const selfData : UserData = {
                data: self.data
            };
            setSchoolIsLocked(self.schoolIsLocked);
            setUserData(selfData);

            const mustChangePassword = selfData.data.find(
                d => d.name === Constants.MUSTCHANGEPASSWORDSETTINGNAME);
            setMustChangePassword(mustChangePassword != null);
        }

        if (!authLoading && userCredential) {
            setMessage({message: "", variant: "info"});

            fetchData(userCredential.token).then(() => {
                if (mustChangePassword) {
                    setMessage({message: recommendChangePassword, href: "/user/profile", variant: "error"});
                }}).catch(e => {
                    setMessage({ message: failedToLoadDashboard, variant: "error" });
                });
            setLoading(false);
        }
    }, [authLoading, userCredential, mustChangePassword]);

    const getSchoolIsLockedContent = () => {
        return <MessageComponent message={schoolIsLockedMessage} variant="error"/>;
    }

    const getDashboardContent = () => {
        return <>
            <Grid item md={12} sm={12} xs={12}>
                <Typography variant="h4" align="center" gutterBottom>
                    {welcome}
                </Typography>
            </Grid>
            {message.message.length > 0 &&
                    <MessageComponent message={message.message} href={message.href} variant={message.variant}/>}
            {userCredential?.isSa ? <DashboardSa/> : <DashboardNonSa userData={userData}/>}
        </>
    }

    const getContent = () => {
        return <Box sx={{m: 2}}>
            <Grid container rowSpacing={2} columnSpacing={2} justifyContent="center">
                {schoolIsLocked ? getSchoolIsLockedContent() : getDashboardContent()}
            </Grid>
        </Box>
    }

    return (
        <>
            {loading ? <LoadingComponent/> : getContent()}
        </>
    );
}
