import { User } from "firebase/auth";
import { KeyboardEvent, useEffect, useState } from "react";
import axios from "axios";
import {
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle, 
    FormControl,
    Stack,
    TextField
} from "@mui/material";
import {
    addDepartmentTitle,
    cancel,
    departmentNameLabel,
    editDepartmentTitle,
    enterValue,
    failedToAddDepartment,
    failedToEditDepartment,
    save
} from "../../../services/Messages";
import ApiService from "../../../services/ApiService";
import { Department } from "../../../types/departments/Department";
import { LoadingComponent } from "../../../components/LoadingComponent";
import { MessageComponent, MessageProps } from "../../../components/MessageComponent";

interface AddOrEditDepartmentModalProps {
    show: boolean;
    department: Department | null;
    schoolId: string;
    user: User;
    onSubmitCallback: (department : Department, wasAdded : boolean) => void;
    onCancelCallback: () => void;
}

export const AddOrEditDepartmentModal = ({
    show,
    department,
    schoolId,
    user,
    onSubmitCallback,
    onCancelCallback
} : AddOrEditDepartmentModalProps) => {
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [message, setMessage] = useState<MessageProps>({message: "", variant: "info"});
    const [departmentName, setDepartmentName] = useState<string>("");

    const isAdd = department == null;

    useEffect(() => {
        setMessage({message: "", variant: "info"});
        if (department) {
            setDepartmentName(department.name);
        } else {
            clearForm();
        }
    }, [department]);

    const getContent = () => {
        return <FormControl fullWidth>
            <TextField autoFocus label={departmentNameLabel} value={departmentName}
                variant="outlined" id="department" name="department"
                onChange={e => setDepartmentName(e.target.value)}/>
        </FormControl>
    }

    const onKeyUp = (e : KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            onSubmit();
        }
    }

    const onCancel = () => {
        setMessage({message: "", variant: "info"});
        onCancelCallback();
    }

    const clearForm = () => {
        setDepartmentName("");
    }

    const onSubmit = async () => {
        try {
            setIsBusy(true);
            isAdd ? await addDepartment() : await editDepartment();
            setIsBusy(false);
        } catch (error) {
            if (axios.isAxiosError(error) && error.response?.data?.error) {
                setMessage({message: error.response.data.error, variant: "error"});
            } else {
                setMessage({message: isAdd ? failedToAddDepartment : failedToEditDepartment, variant: "error"});
            }
            setIsBusy(false);
        }
    }
    
    const addDepartment = async () => {
        setMessage({message: "", variant: "info"});

        const departName = departmentName.trim();
        if (departName.length === 0) {
            setMessage({message: enterValue, variant: "error"});
            return;
        }
        const token = await user.getIdToken();
        const response = await ApiService.addDepartment({
            department: {
                name: departName,
                schoolId: schoolId
            },
            token: token
        });

        const department = {
            id: response.data["id"],
            name: response.data["name"]
        }
        clearForm();
        onSubmitCallback(department, isAdd);
    }

    const editDepartment = async () => {
        setMessage({message: "", variant: "info"});
        const departName = departmentName.trim();
        
        if (departName.length === 0) {
            setMessage({message: enterValue, variant: "error"});
            return;
        }
        const token = await user.getIdToken();
        const response = await ApiService.updateDepartment( department?.id ? department.id.toString() : "", {
            name: departName,
            schoolId: schoolId
        }, token);
        const departmentResponse = {
            id: response.data["id"],
            name: response.data["name"]
        }
        clearForm();
        onSubmitCallback(departmentResponse, isAdd);
    }

    return <Dialog open={show} onKeyUp={onKeyUp} onClose={onCancel} disableRestoreFocus>
        <DialogTitle>{isAdd ? addDepartmentTitle : editDepartmentTitle}</DialogTitle>
        <DialogContent>
            <Stack spacing={1} sx={{m: 1}}>
                {message.message.length > 0 && <MessageComponent message={message.message} variant={message.variant}/>}
                {isBusy ? <LoadingComponent/> : getContent()}
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" disabled={isBusy} onClick={onCancel}>
                {cancel}
            </Button>
            <Button disabled={isBusy} onClick={onSubmit}>
                {save}
            </Button>
        </DialogActions>
    </Dialog>
}