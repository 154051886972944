import {useState} from "react";
import {
    AlertColor, 
    Button, 
    Stack, 
    TextField
} from "@mui/material";
import {HandleForm} from "../../../components/HandleForm";
import {
    successForgotPassword,
    failedForgotPassword,
    forgotPassword,
    enterEmail,
    requestPasswordReset
} from "../../../services/Messages";
import AuthService from "../../../services/AuthService";
import {LoadingComponent} from "../../../components/LoadingComponent";

interface ForgotPasswordFormProps {
    onErrorCallback: (errorMessage : string, variant : AlertColor) => void;
    onSuccessCallback: (message : string, variant : AlertColor) => void;
}

export const ForgotPasswordForm = ({onErrorCallback, onSuccessCallback} : ForgotPasswordFormProps) => {
    const initialFormState = {
        email: ""
    };

    const [isBusy, setIsBusy] = useState<boolean>(false);

    const getContent = () => {
        return <form onSubmit={onSubmit}>
            <Stack spacing={1}>
                <p>{forgotPassword}</p>
                <TextField id="email" variant="outlined" required aria-describedby={enterEmail} placeholder={enterEmail} 
                    name="email" onChange={onChange}/>
                <Button variant="contained" type="submit" onClick={() => onSubmit}>{requestPasswordReset}</Button>
            </Stack>
        </form>
    }

    const handleSubmit = async () => {
        try {
            setIsBusy(true);
            const map = new Map(Object.entries(values));
            const email: string = map.get("email") as string;
            await AuthService.forgotPassword(email);
            setIsBusy(false);
            onSuccessCallback(successForgotPassword, "success");
        } catch(e) {
            setIsBusy(false);
            onErrorCallback(failedForgotPassword, "error");
        }
    }

    const {onChange, onSubmit, values} = HandleForm(
        handleSubmit,
        initialFormState
    );

    return <>
        {isBusy ? <LoadingComponent/> : getContent()}
    </>
}