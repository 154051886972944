import React from "react";
import {useContext} from "react";
import AppBar from "@mui/material/AppBar";
import {
    Box, 
    IconButton, 
    Link, 
    Menu, 
    MenuItem, 
    Toolbar, 
    Typography
} from "@mui/material";
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import {AuthContext} from "../context/AuthContext";
import {
    altTextLogo,
    userSettingsTitle,
    logOut
} from "../services/Messages";

export const Header = () => {
    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
    const authContext = useContext(AuthContext);

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
      };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position="static" style={{ background: "#303234"}}>
            <Toolbar>
                <Link href="/">
                    <Box component="img" 
                        sx={{width: 180}} 
                        alt={altTextLogo} 
                        src={require("../assets/logos/logo-dark.png")}/>
                </Link>

                {authContext.user && 
                <Box sx={{ marginLeft: "auto" }}>
                    <IconButton onClick={handleOpenUserMenu}>
                        <PersonOutlineIcon style={{fontSize: 40}} className="logged-in-user-icon"/>
                    </IconButton>

                    <Menu
                        sx={{ mt: '45px' }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={handleCloseUserMenu}
                        >
                        <MenuItem component={"a"} href="/user/profile" onClick={handleCloseUserMenu}>
                            <Typography textAlign="center">{userSettingsTitle}</Typography>
                        </MenuItem>
                        <MenuItem component={"a"} href="/logout" onClick={handleCloseUserMenu}>
                            <Typography textAlign="center">{logOut}</Typography>
                        </MenuItem>
                    </Menu>
                </Box>}
            </Toolbar>
        </AppBar>
    );
}
