import {useState} from "react";
import {
    AlertColor, 
    Stack,
    Button, 
    FormControlLabel, 
    Switch, 
    TextField
} from "@mui/material";
import {useNavigate} from "react-router-dom";
import AuthService from "../../../services/AuthService";
import {
    failedLogin,
    enterEmail,
    enterPassword,
    rememberMe,
    logIn,
    notAuthorisedOrUserConfigError,
} from "../../../services/Messages";
import {HandleForm} from "../../../components/HandleForm";
import {LoadingComponent} from "../../../components/LoadingComponent";
import {UserCredential} from "firebase/auth";
import {getJwtClaims} from "../../../utils/utils";
import {Roles} from "../../../types/users/Roles";

interface LoginFormProps {
    onErrorCallback: (errorMessage: string, variant: AlertColor) => void;
}

export const LoginForm = ({onErrorCallback}: LoginFormProps) => {
    const initialFormState = {
        email: "",
        password: "",
        rememberMe: "",
    };

    const [isBusy, setIsBusy] = useState<boolean>(false);
    const navigate = useNavigate();

    const getContent = () => {
        return <form onSubmit={onSubmit}>
            <Stack spacing={1}>
                <TextField id="email" type="email" variant="outlined" required aria-describedby={enterEmail} placeholder={enterEmail} 
                            name="email" onChange={onChange}/>
                <TextField id="password" type="password" variant="outlined" required aria-describedby={enterEmail} placeholder={enterPassword} 
                            name="password" onChange={onChange}/>
                <FormControlLabel control={<Switch onChange={onChange} name="rememberMe"/> } label={rememberMe} />
                <Button variant="contained" type="submit">{logIn}</Button>
            </Stack>
        </form>
    }

    const handleSubmit = async () => {
        try {
            setIsBusy(true);
            const map = new Map(Object.entries(values));
            const email: string = map.get("email") as string;
            const password: string = map.get("password") as string;
            const rememberMe: boolean = map.get(`rememberMe`) as string === "on";
            const credential : UserCredential = await AuthService.logIn(email, password, rememberMe);

            const token : string = await credential.user.getIdToken();
            const claims = getJwtClaims(token);

            if (claims.role === Roles.student) {
                await AuthService.logOut();
                onErrorCallback(notAuthorisedOrUserConfigError, "error");
                return;
            }
            navigate("/dashboard");
        } catch (error) {
            setIsBusy(false);
            onErrorCallback(failedLogin, "error");
        }
    }

    const {onChange, onSubmit, values} = HandleForm(
        handleSubmit,
        initialFormState
    );

    return (
        <>
            {isBusy ? <LoadingComponent/> : getContent()}
        </>
    );
}