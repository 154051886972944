import {useState} from "react";
import {User} from "firebase/auth";
import {
    Button,
    FormControl, 
    Stack, 
    TextField
} from "@mui/material";
import AuthService from "../../../services/AuthService";
import ApiService from "../../../services/ApiService";
import {
    failedChangePasswordMatch,
    failedChangePasswordLength,
    successChangePassword,
    failedChangePassword,
    enterCurrentPassword,
    doChangePassword,
    enterNewPassword,
    confirmNewPassword
} from "../../../services/Messages"
import {Constants} from "../../../Constants";
import {HandleForm} from "../../../components/HandleForm";
import {LoadingComponent} from "../../../components/LoadingComponent";
import {MessageComponent, MessageProps} from "../../../components/MessageComponent";

interface ChangePasswordFormProps {
    firebaseUser: User | null;
}

export const ChangePasswordForm = ({firebaseUser} : ChangePasswordFormProps) => {
    const initialFormState = {
        currentPassword: "",
        newPassword: "",
        confirmNewPassword: ""
    };

    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [message, setMessage] = useState<MessageProps>({message: "", variant: "info"});

    const getContent = () => {
        return <>
            {message.message.length > 0 && <MessageComponent message={message.message} href={message.href} variant={message.variant}/>}
            <form onSubmit={onSubmit}>
                <FormControl fullWidth sx={{m: 1}}>
                    <Stack spacing={1}>
                        <TextField id="currentPassword" label={enterCurrentPassword} type="password" variant="outlined" required 
                            name="currentPassword" onChange={onChange}/>
                        <TextField id="newPassword" label={enterNewPassword} type="password" variant="outlined" required 
                            name="newPassword" onChange={onChange}/>
                        <TextField id="confirmNewPassword" label={confirmNewPassword} type="password" variant="outlined" required 
                            name="confirmNewPassword" onChange={onChange}/>
                        <Button variant="contained" type="submit">{doChangePassword}</Button>
                    </Stack>
                </FormControl>
            </form>
        </>
    }

    const handleSubmit = async () => {
        try {
            const map = new Map(Object.entries(values));
            const currentPassword : string = map.get("currentPassword") as string;
            const newPassword: string = map.get("newPassword") as string;
            const confirmNewPassword: string = map.get("confirmNewPassword") as string;

            if (newPassword !== confirmNewPassword) {
                setMessage({ message: failedChangePasswordMatch, variant: "error" });
                return;
            }
            if (newPassword.length < 6) {
                setMessage({ message: failedChangePasswordLength, variant: "error" });
                return;
            }

            setIsBusy(true);
            await AuthService.changePassword(firebaseUser, currentPassword, newPassword);
            await clearMustChangePassword();
            setMessage({ message: successChangePassword, href: "/", variant: "success" });
            setIsBusy(false);
        } catch(e) {
            setMessage({ message: failedChangePassword, variant: "error" });
            setIsBusy(false);
        }
    }

    const clearMustChangePassword = async () => {
        const token = await firebaseUser?.getIdToken();
        await ApiService.deleteSelfData(Constants.MUSTCHANGEPASSWORDSETTINGNAME, token);
    }

    const {onChange, onSubmit, values} = HandleForm(
        handleSubmit,
        initialFormState
    );

    return (
        <>
            {isBusy ? <LoadingComponent/> : getContent()}
        </>
    );
}