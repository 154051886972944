import {Alert, Box, Button} from "@mui/material";
import {GridColDef} from "@mui/x-data-grid";
import {User} from "firebase/auth";
import {useEffect, useState} from "react";
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import {
    addTermGetStarted,
    term,
    definitions,
    doDelete,
    doEdit,
    requireMinimumTerms,
    add,
    failedToGetTerms,
    termDeleted,
    termAdded,
    termEdited,
    wrongDefinitions
} from "../../../services/Messages";
import ApiService from "../../../services/ApiService";
import {Question} from "../../../types/questions/Question";
import {Answer} from "../../../types/questions/Answer";
import {AddOrEditQuestionModal} from "./AddOrEditQuestionModal";
import {DeleteQuestionModal} from "./DeleteQuestionModal";
import {DataTableClientSide} from "../../../components/DataTableClientSide";
import {MessageComponent, MessageProps} from "../../../components/MessageComponent";

interface QuestionsTableProps {
    topicId: string;
    user: User;
}

export const QuestionsTable = ({topicId, user}: QuestionsTableProps) => {
    const recommendedTermsCount : number = 4;

    const [message, setMessage] = useState<MessageProps>({message: "", variant: "info"});
    const [questions, setQuestions] = useState<Array<Question>>([]);
    const [dataLoading, setDataLoading] = useState<boolean>(true);
    const [question, setQuestion] = useState<Question | null>(null);
    const [showAmendQuestion, setShowAmendQuestion] = useState<boolean>(false);
    const [showDeleteQuestion, setShowDeleteQuestion] = useState<boolean>(false);

    const onEditQuestion = (question : Question) => {
        setQuestion(question);
        setShowAmendQuestion(true);
    };

    const columns : GridColDef[] = [{
            field: "text", 
            headerName: term, 
            width: 250, 
            sortable: false, 
            filterable: false,
            hideable: false
        }, {
            field: "answers", 
            headerName: definitions, 
            width: 350, 
            sortable: false,
            filterable: false,
            hideable: false,
            renderCell: params => {
                const as : Answer[] = params.value as Answer[];
                let val : string = "";
                as.map((a, index) => {
                    val += a.text;
                    if (index !== as.length - 1) {
                        val += ", ";
                    }
                    return val;
                });
                return <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                    {val}
                </span>;
            },
        }, {
            field: "wrongAnswers", 
            headerName: wrongDefinitions, 
            width: 250, 
            sortable: false,
            filterable: false,
            hideable: false,
            renderCell: params => {
                const was : string[] = params.value as string[];
                let val : string = "";
                if (was) {
                    was.map((a, index) => {
                        val += a;
                        if (index !== was.length - 1) {
                            val += ", ";
                        }
                        return val;
                    });
                }
                return <span style={{ overflow: "hidden", textOverflow: "ellipsis" }}>
                    {val}
                </span>;
            },

        }, {
            field: "resources",
            headerName: "Resources",
            width: 200,
            sortable: false,
            filterable: false,
            hideable: false,
        }, {
            field: "edit",
            headerName: "",
            width: 100,
            sortable: false,
            filterable: false,
            hideable: false,
            renderCell: params => {
                const qs : Question = params.row as Question
                return <Button 
                        variant="contained"
                        onClick={(event) => {
                            onEditQuestion(qs);
                        }}>
                        <EditIcon/>&nbsp;{doEdit}
                </Button>
            }
        }, { 
            field: "delete", 
            headerName: "", 
            width: 120,
            sortable: false,
            filterable: false,
            hideable: false,
            renderCell: params => {
                const qs : Question = params.row as Question
                return <Button
                        variant="contained"
                        onClick={(event) => {
                            onDeleteQuestion(qs);
                        }}>
                        <DeleteIcon/>&nbsp;{doDelete}
                </Button>
            }
         }
    ];
    
    useEffect(() => { 
        const fetchQuestions = async () => {
            const token = await user.getIdToken();
            const questions = await ApiService.getQuestions(topicId, token);
            setQuestions(questions);
        }
        fetchQuestions().then(() => {
            setMessage({ message: "", variant: "info" });
            setDataLoading(false);
        }).catch(e => {
            setMessage({message: failedToGetTerms, variant: "error"});
            setDataLoading(false);
        });
    }, [topicId, user]);

    const onDeleteQuestion = (question : Question) => {
        setMessage({message: "", variant: "info"});
        setQuestion(question);
        setShowDeleteQuestion(true);
    };

    const onCancelDeleteQuestion = () => {
        setQuestion(null);
        setShowDeleteQuestion(false);
    };

    const onQuestionDeleted = (question : Question) => {
        let qs = questions.filter((q) => q.id !== question.id);
        setQuestions(qs);
        setQuestion(null);
        setShowDeleteQuestion(false);
        setMessage({ message: termDeleted, variant: "success" });
    };

    const onAddQuestion = () => {
        setQuestion(null);
        setShowAmendQuestion(true);
    };

    const onCancelAmendQuestion = () => {
        setQuestion(null);
        setShowAmendQuestion(false);
    }

    const onQuestionAmended = (question : Question, wasAdded : boolean) => {
        if (wasAdded) {
            setQuestions([...questions, question]);
            setShowAmendQuestion(false);
            setQuestion(null);
            setMessage({ message: termAdded, variant: "success" });
        } else {
            const newQuestions = [...questions];
            const questionIndex = newQuestions.findIndex((q) => q.id === question.id);

            newQuestions[questionIndex] = question;
            setQuestions(newQuestions);

            setQuestion(null);
            setShowAmendQuestion(false);
            setMessage({ message: termEdited, variant: "success" });
        }
    }

    return (
        <>
            <AddOrEditQuestionModal
                show={showAmendQuestion}
                topicId={topicId}
                question={question}
                user={user}
                onSubmitCallback={onQuestionAmended}
                onCancelCallback={onCancelAmendQuestion}/>

            <DeleteQuestionModal
                show={showDeleteQuestion}
                question={question}
                user={user}
                onSubmitCallback={onQuestionDeleted}
                onCancelCallback={onCancelDeleteQuestion}/>

            {questions.length === 0 && <p className="lead">{addTermGetStarted}</p>}

            {questions && questions.length < recommendedTermsCount && (
                <Alert severity="warning">{requireMinimumTerms}</Alert>
            )}
            
            {message.message.length > 0 && <MessageComponent message={message.message} variant={message.variant}/>}

            <DataTableClientSide
                loading={dataLoading}
                disableColumnMenu={true}
                filterable={false}
                columns={columns}
                rows={questions}/>

            <Box sx={{mt: 2}}>
                <Button variant="contained" onClick={onAddQuestion}>
                    <AddIcon/>&nbsp;{add}
                </Button>
            </Box>
        </>
    )
}