import {useEffect} from "react";
import {useNavigate} from "react-router-dom";
import AuthService from "../services/AuthService";
import {LoadingComponent} from "../components/LoadingComponent";

export const LogoutPage = () => {
    const navigate = useNavigate();

    useEffect( () => {
        const logout = async () => {
            try {
                await AuthService.logOut();
                localStorage.clear();
            } catch(e) {
                // This error can only be swallowed
            }
        }
        logout().then(() => {
            navigate("/");
        });
    });

    return <LoadingComponent/>;
}