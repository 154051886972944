import {useContext} from "react";
import {
    Grid, 
    Card, 
    IconButton, 
    CardContent, 
    Typography, 
    Stack, 
    Box, 
    CardActionArea
} from "@mui/material";
import {Navigate} from "react-router";
import PersonIcon from '@mui/icons-material/Person';
import {AuthContext} from "../../context/AuthContext";
import {logIn, logInDescription} from "../../services/Messages";
import {CreateGameCard} from "../dashboard/components/CreateGameCard";
import {LoadingComponent} from "../../components/LoadingComponent";

export const LandingPage = () => {
    const authContext = useContext(AuthContext);

    const getLoginCard = () => {
        return <Box sx={{boxShadow: 2, height: "100%"}}>
            <Card variant="outlined" sx={{height: "100%"}}>
                <CardContent>
                    <CardActionArea href="/login" disableRipple disableTouchRipple>
                        <Box sx={{width: "100%"}}>
                            <Stack spacing={1} alignContent="center">
                                <IconButton disableRipple disableFocusRipple>
                                    <PersonIcon style={{fontSize: 40, color: "#22B8DC"}}/>
                                </IconButton>
                                <Typography variant="h5" align="center">
                                    {logIn}
                                </Typography>
                                <Typography variant="body2" color="text.secondary" align="center">
                                    {logInDescription}
                                </Typography>
                            </Stack>
                        </Box>
                    </CardActionArea>
                </CardContent>
            </Card>
        </Box>
    }

    const getContent = () => {
        return <Box sx={{m: 2}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={6}>
                    {getLoginCard()}
                </Grid>
                <Grid item xs={12} sm={6} md={6}>
                    <CreateGameCard/>
                </Grid>
            </Grid>
        </Box>
    }

    return <>
            {authContext.loading ? <LoadingComponent/> :
                authContext.user ? <Navigate to="/dashboard" replace/> : getContent()}
    </>
}