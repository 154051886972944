import {default as Breadcrumb} from '@mui/material/Breadcrumbs';
import {Typography, Link} from '@mui/material';

interface BreadcrumbsProps {
    crumbs : {
        name : string;
        path : string; // Put this as "#" for the active item
    }[];
}

export const Breadcrumbs = ({crumbs} : BreadcrumbsProps ) => {
    return (
        <Breadcrumb>
            {crumbs.map(({name, path}, key) => {
                if(path === "#") { // Magic hash
                    return <Typography key={key} color="text.primary">
                        {name}
                    </Typography>
                } else {
                    return <Link key={key} underline="hover" href={path}>
                        {name}
                    </Link>
                }
            })}
        </Breadcrumb>
    )
}