import {User} from "firebase/auth";
import {KeyboardEvent, useEffect, useState} from "react";
import {
    Button, 
    Dialog, 
    DialogActions, 
    DialogContent, 
    DialogTitle,
    Stack,
    Typography
} from "@mui/material";
import {
    cancel,
    confirmDelete,
    confirmDeleteDescription,
    doDelete,
    failedToDeleteTerm
} from "../../../services/Messages";
import ApiService from "../../../services/ApiService";
import {Question} from "../../../types/questions/Question";
import {LoadingComponent} from "../../../components/LoadingComponent";
import {MessageComponent, MessageProps} from "../../../components/MessageComponent";

interface DeleteQuestionModalProps {
    show: boolean;
    user: User,
    question: Question | null;
    onCancelCallback: () => void;
    onSubmitCallback: (question : Question) => void;
}

export const DeleteQuestionModal = ({
                              show,
                              user,
                              question,
                              onCancelCallback,
                              onSubmitCallback}: DeleteQuestionModalProps) => {
    const [isBusy, setIsBusy] = useState<boolean>(false);
    const [message, setMessage] = useState<MessageProps>({message: "", variant: "info"});

    useEffect(() => {
        setMessage({message: "", variant: "info"});
        if (!question) {
            setMessage({message: failedToDeleteTerm, variant: "error"});
        }
    }, [question]);

    const getContent = () => {
        return <>
            {isBusy ? <LoadingComponent/> : <Typography variant="h6">{confirmDeleteDescription}</Typography>}
        </>
    }

    const onDelete = async () => {
        try {
            setMessage({message: "", variant: "info"});
            setIsBusy(true);
            let token = await user.getIdToken();
            await ApiService.deleteQuestion(question!.id, token);
            setIsBusy(false);
            onSubmitCallback(question!);
        } catch(e) {
            setMessage({message: failedToDeleteTerm, variant: "error"});
            setIsBusy(false);
        }
    }

    const onKeyUp = (e : KeyboardEvent<HTMLDivElement>) => {
        if (e.key === "Enter") {
            onDelete();
        }
    }

    return <Dialog open={show} onKeyUp={onKeyUp} onClose={onCancelCallback} disableRestoreFocus>
        <DialogTitle>{confirmDelete}</DialogTitle>
        <DialogContent>
            <Stack spacing={1} sx={{m: 1}}>
                {message.message.length > 0 && <MessageComponent message={message.message} variant={message.variant}/>}
                {isBusy ? <LoadingComponent/> : getContent()}
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button color="secondary" disabled={isBusy} onClick={onCancelCallback}>
                {cancel}
            </Button>
            <Button disabled={!question || isBusy} onClick={onDelete}>
                {doDelete}
            </Button>
        </DialogActions>
    </Dialog>
}