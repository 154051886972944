import {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {
    Box, 
    Button, 
    Card, 
    CardActionArea, 
    CardContent, 
    Grid, 
    Stack, 
    Typography
} from "@mui/material";
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import {
    allDepartments,
    doSetDefaultDepartment,
    failedToLoadDashboard,
    manage,
    manageDescription,
    manageTopicsFor
} from "../../../services/Messages";
import ApiService from "../../../services/ApiService";
import {Department} from "../../../types/departments/Department";
import {UserData} from "../../../types/users/UserData";
import {Constants} from "../../../Constants";
import {AuthContext} from "../../../context/AuthContext";
import {LoadingComponent} from "../../../components/LoadingComponent";
import {MessageComponent, MessageProps} from "../../../components/MessageComponent";
import {CreateGameCard} from "./CreateGameCard";
import {ManageUsersCard} from "./ManageUsersCard";
import {SetDefaultDepartmentModal} from "./SetDefaultDepartmentModal";

interface DashboardContentNonSaProps {
    userData : UserData;
}

export const DashboardNonSa = ({
                                    userData,
                               } : DashboardContentNonSaProps) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [message, setMessage] = useState<MessageProps>({message: "", variant: "info"});
    const [showSetDefaultDepartment, setShowSetDefaultDepartment] = useState<boolean>(false);
    const [defaultDepartment, setDefaultDepartment] = useState<Department | null>(null);

    const navigate = useNavigate();
    const {loading : authLoading, user, userCredential} = useContext(AuthContext);

    useEffect(() => {
        const fetchData = async (token : string) => {
            const defaultDepartmentId = userData.data.find(data => 
                data.name === Constants.DEFAULTDEPARTMENTIDSETTINGNAME);
            if (defaultDepartmentId) {
                const department = await ApiService.getDepartment(defaultDepartmentId.value, token);
                setDefaultDepartment(department);
            }
        }

        if (!authLoading && userCredential) {
            fetchData(userCredential.token).catch(e => {
                setMessage({message: failedToLoadDashboard, variant: "error"});
            }).finally(() => {
                setLoading(false);
            });
        }
    }, [authLoading, userCredential, userData.data])

    const onGoToDepartments = () => {
        if (defaultDepartment) {
            navigate(`/depts/${defaultDepartment.id}/topics`);
        } else {
            navigate("/depts");
        }
    }

    const onGoToAllDepartments = () => {
        navigate("/depts");
    }

    const onSetDefaultDepartment = () => {
        setShowSetDefaultDepartment(true);
    }

    const onSetDefaultDepartmentDone = (department: Department | null) => {
        setDefaultDepartment(department);
        setShowSetDefaultDepartment(false);
    }

    const onSetDefaultDepartmentCancelled = () => {
        setShowSetDefaultDepartment(false);
    }

    const getManageDepartmentsCard = () => {
        return <Box sx={{boxShadow: 2, height: "100%"}}>
        <Card variant="outlined" sx={{height: "100%"}}>
            <CardContent>
                <CardActionArea onClick={onGoToDepartments} disableRipple disableTouchRipple>
                    <Stack alignContent="center" textAlign="center">
                        <Box display="flex" justifyContent="center" alignItems="center">
                            <LibraryBooksIcon style={{fontSize: 40, color: "#22B8DC"}}/>
                        </Box>
                        <Typography variant="h5">
                            {manage}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {defaultDepartment && `${manageTopicsFor} ${defaultDepartment?.name}`}
                            {!defaultDepartment && `${manageDescription}`}
                        </Typography>
                    </Stack>
                </CardActionArea>
                <Stack alignContent="center">
                    <Button variant="text" onClick={onSetDefaultDepartment}>{doSetDefaultDepartment}</Button>
                    <Button variant="text" onClick={onGoToAllDepartments}>{allDepartments}</Button>
                </Stack>
            </CardContent>
        </Card>
    </Box>
    }

    const getContent = () => {
        return <>
            <SetDefaultDepartmentModal
                show={showSetDefaultDepartment}
                firebaseUser={user}
                defaultDepartmentId={defaultDepartment ? defaultDepartment.id.toString() : ""}
                schoolId={userCredential?.schoolId}
                onSubmitCallback={onSetDefaultDepartmentDone}
                onCancelCallback={onSetDefaultDepartmentCancelled}/>

            {message.message.length > 0 &&
                <MessageComponent message={message.message} href={message.href} variant={message.variant}/>}
            
            <Grid item md={6} sm={12} xs={12}>
                {getManageDepartmentsCard()}
            </Grid>
            <Grid item md={6} sm={12} xs={12}>
                <CreateGameCard/>
            </Grid>
            {userCredential?.isAdmin && <Grid item md={6} sm={12} xs={12}>
                    <ManageUsersCard/>
            </Grid>}
        </>
    }

    return (
        <>
            {loading ? <LoadingComponent/> : getContent()}
        </>
    );
}