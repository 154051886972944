import {createContext, ReactNode, useState, useEffect} from "react";
import {User} from "firebase/auth";
import AuthService from "../services/AuthService";
import {Roles} from "../types/users/Roles";
import {UserCredential} from "../types/users/UserCredential";
import {getJwtClaims} from "../utils/utils";

type AppUser = User | null;
type ContextState = {
    user: AppUser,
    userCredential: UserCredential | null,
    loading: boolean,
    changeSchoolId: ((schoolId : string) => void)
};

type ContextProps = {
    children?: ReactNode
};

const AuthContext = createContext<ContextState>({user : null, userCredential: null, loading: true, changeSchoolId: (schoolId : string) => {}});

const AuthProvider = ({ children} : ContextProps) => {
    const [user, setUser] = useState<AppUser>(null);
    const [userCredential, setUserCredential] = useState<UserCredential | null>(null);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        AuthService.auth.onAuthStateChanged(user => {
            setUser(user);
            setUserCredential(null);

            if (user) {
                setLoading(true);
                user.getIdToken().then(token => {
                    const claims = getJwtClaims(token);
                    const schoolId = getSchoolId(claims.schoolId ? claims.schoolId : "");
                    setUserCredential({
                        token: token, 
                        role: claims.role,
                        isAllowed: claims.role.length > 0 && claims.role !== Roles.student,
                        isAdmin: claims.role === Roles.admin || claims.role === Roles.sa,
                        isSa: claims.role === Roles.sa,
                        schoolId: schoolId
                    });
                }).catch(e => {
                    // Log it?
                }).finally(() => {
                    setLoading(false);
                })
            } else {
                setUser(null);
                setUserCredential(null);
                setLoading(false);
            }
        });
        
    }, []);

    const getSchoolId = (userSchoolId : string) => {
        if (userSchoolId.length === 0) {
            userSchoolId = localStorage.getItem("schoolId") || ""
        }
        return userSchoolId;
    }

    const changeSchoolId = (schoolId : string) => {
        let credential = userCredential;
        if (credential) {
            credential.schoolId = schoolId;
            setUserCredential(credential);
            localStorage.setItem("schoolId", schoolId);
        } else {
            localStorage.setItem("schoolId", "");
        }
    }

    return (
        <AuthContext.Provider value={{user, userCredential, loading, changeSchoolId}}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthProvider, AuthContext };